.cv__textfieldaction-flex {
  display: flex;
  align-items: center;
  gap: 20px;

  .cv__drag {
    font-size: 24px;
    cursor: grab;
    color: var(--color-grey);
  }

  button {
    flex-shrink: 0;
  }
}
