#cv__editor {
  display: flex;
  justify-content: center;
  padding: 20px;
  overflow-y: auto;
  width: 100%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

  & > div {
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: fit-content;
  }

  .cv__editor-flex {
    display: flex;

    & > div:last-child {
      margin: 0 0 0 20px;
    }
  }

  .MuiTextField-root {
    width: 100%;
  }

  .cv__pinfo-photo {
    display: flex;
    gap: 20px;
    align-items: center;
    div {
      flex-grow: 1;
      border: 1px dashed var(--color-border-grey);
      padding: 16.5px 14px;
      border-radius: 10px;
    }
    span {
      font-weight: 600;
    }
  }
}

// fix display bug of MDEditor
.w-md-editor-text-pre {
  display: none !important;
}

.w-md-editor-text-input {
  -webkit-font-smoothing: auto !important;
  -webkit-text-fill-color: inherit !important;
}
