.cv__mdeditorproaction-flex {
  display: flex;
  align-items: center;

  .MuiTextField-root {
    margin-right: 20px;
  }

  button {
    flex-shrink: 0;
  }
}
