#cv__builder {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - var(--height-navbar));

  #cv__rightpanel {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 420px;
    padding: 20px 20px 20px 0;

    .cv__rightpanel-btns {
      display: flex;
      gap: 20px;

      button {
        flex: 1;
        border-radius: 12px;
      }
    }
  }
}

.cv__dialogpro-save {
  padding: 20px;
  width: 600px;
  p {
    text-align: center;
    padding: 15px;
  }
  button {
    width: 100%;
  }
  .MuiPaper-root {
    margin-top: 20px;
  }
  .MuiTextField-root {
    width: 100%;
    margin-bottom: 20px;
  }
  .cv__dialogpro-btns {
    display: flex;
    gap: 20px;
    button {
      flex: 1;
    }
  }
}
