@color-grey: #888;
@color-grey-light: #f6f6f6;

#cvt__minimalist {
  padding: 20px 40px;

  .cvt__minimalist-name {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    margin: 16px 0;
    line-height: 1;
  }

  .cvt__minimalist-jobtitle {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .cvt__minimalist-wrap {
    display: flex;

    .cvt__minimalist-left {
      flex: 1 0;
      margin-right: 16px;
    }

    .cvt__minimalist-right {
      flex: 2 0;
    }

    & > div {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      > div:last-child {
        flex-grow: 1;
      }
    }
  }

  .cvt__minimalist-section {
    border-radius: 12px;
    background-color: @color-grey-light;
    padding: 12px 16px;

    & + .cvt__minimalist-section {
      margin-top: 16px;
    }

    .cvt__minimalist-section-title {
      margin-bottom: 10px;
    }
  }

  .cvt__minimalist-contact {
    & > div {
      display: flex;

      & + div {
        margin-top: 6px;
      }

      svg {
        font-size: 20px;
        margin-right: 10px;
        color: @color-grey;
      }

      p {
        overflow: hidden;
        overflow-wrap: break-word;
      }
    }
  }

  .cvt__minimalist-intro {
    text-align: justify;
  }

  .cvt__minimalist-edu-item {
    .cvt__minimalist-edu-item-flex {
      display: flex;
      justify-content: space-between;
    }

    & + .cvt__minimalist-edu-item {
      margin-top: 10px;
    }
  }

  .cvt__minimalist-skills-skillset,
  .cvt__minimalist-hobbies-items {
    margin-bottom: 10px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    p {
      display: inline;
      overflow-wrap: break-word;
    }
  }

  .cvt__minimalist-projects-item {
    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .cvt__minimalist-projects-wrap > p {
      margin-bottom: 6px;
    }
  }

  .cvt__minimalist-experiences-item {
    & > p {
      margin-bottom: 6px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .cvt__minimalist-experiences-flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
    }
  }
}
