@gap: 60px;

#cv__dashboard {
  height: calc(100vh - var(--height-navbar));
  display: flex;
  flex-direction: column;
  gap: @gap;

  .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 1060px;
    margin: @gap auto 0;

    .info {
      display: flex;
      align-items: center;
      gap: var(--gap);
      margin-bottom: @gap;
      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid var(--color-theme-grey);
      }
      span {
        font-weight: 600;
        font-size: 24px;
      }
      .create {
        margin-left: auto;
      }
    }

    .tabs {
      display: flex;
      gap: 8px;
      border-bottom: 1px solid var(--color-theme-grey);
      box-sizing: border-box;
      padding-bottom: 4px;
      margin-bottom: var(--gap);
      font-weight: 600;
      a {
        padding: 6px 14px;
        display: inline-block;
        border-radius: 10px;
        &:hover {
          background-color: var(--color-theme-transparent);
        }
      }
    }

    #cvs {
      .cvs {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: var(--gap);
      }
      .no_data {
        text-align: center;
        margin: var(--gap);
        color: var(--color-grey);
        a {
          font-weight: 600;
        }
      }
      .card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        cursor: pointer;
        &:hover {
          img {
            border-color: var(--color-theme);
          }
          span {
            color: var(--color-theme);
          }
        }
        img {
          border-radius: 12px;
          transition: 0.3s;
          border: var(--card-border);
          width: 100%;
        }
        span {
          transition: 0.3s;
          text-align: center;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    #profile {
      display: flex;
      flex-direction: column;
      gap: var(--gap);
      .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--gap);
      }
    }
  }
}
