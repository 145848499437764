#cv__auth {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .cv__auth-bg {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background: url("../../assets/auth.svg") var(--color-navbar-bg) no-repeat
      center/cover;
    opacity: 0.8;
  }

  .cv__auth-box {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    width: 450px;
    background: var(--color-app-bg);
    backdrop-filter: blur(4px);
    border-radius: 12px;
    padding: 20px;

    h1 {
      text-align: center;
    }

    button {
      width: 100%;
    }

    p {
      text-align: center;
    }

    & > span {
      cursor: pointer;
      text-decoration: underline;
      width: fit-content;
      transition: 0.3s;
      &:hover {
        color: var(--color-theme);
      }
    }
  }
}
