.cv__dialogpro {
  z-index: 1100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 0 0;
  max-height: calc(100vh - 20px * 2);
  animation: zoomOut 0.3s forwards;

  &.zoomIn {
    animation: zoomIn 0.3s forwards;
  }

  .cv__dialogpro-title {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    margin-bottom: 20px;
  }
}

.cv__backdrop {
  position: fixed;
  z-index: 1075;
  background-color: var(--color-backdrop);
  backdrop-filter: blur(4px);
  inset: 0;
  cursor: zoom-out;
  opacity: 0;
  animation: fadeOut 0.3s forwards;

  &.fadeIn {
    animation: fadeIn 0.3s forwards;
  }
}
