.cv__templatecards {
  display: grid;
  width: fit-content;
  margin: 40px auto;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  transition: 0.3s;

  .cv__templatecard {
    width: fit-content;

    .cv__card-img {
      display: block;
      width: 250px;
      height: calc(250px * sqrt(2));
      background-color: #aaa;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: 0.3s;
      }
    }

    .cv__card-title {
      font-size: 14px;
      margin: 10px 0;
      text-align: center;
      font-weight: 600;
      transition: 0.3s;
      width: 250px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &:hover {
      img {
        scale: 1.05;
      }
      .cv__card-title {
        color: var(--color-theme);
      }
    }
  }
}
