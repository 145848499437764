// 共用
.cv__chatgpt-title {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  margin-bottom: 20px;
}

.cv__chatgpt-status {
  align-self: center;
  margin-left: 10px;
}

.cv__chatgpt-msgs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: 10px;
  padding-right: 20px;
  overflow-x: hidden;

  .cv__chatgpt-msg {
    padding: 10px;
    width: fit-content;
    max-width: 80%;
    word-wrap: break-word;

    & + .cv__chatgpt-msg {
      margin-top: 15px;
    }

    &:nth-child(odd) {
      border-radius: 2px 10px 10px 10px;
      background-color: var(--color-bg-msg-odd);

      h1,
      h2,
      ul,
      ol {
        margin-bottom: 10px;
      }
    }

    &:nth-child(even) {
      border-radius: 10px 2px 10px 10px;
      background-color: var(--color-bg-msg-even);
      align-self: flex-end;
      white-space: pre-line;
    }
  }
}

.cv__chatgpt-textarea-frame {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: var(--card-border);
  padding: 8px 8px 8px 10px;
  background-color: var(--color-app-bg);

  &.disabled {
    cursor: not-allowed;

    .cv__chatgpt-textarea {
      cursor: not-allowed;
    }
  }

  .cv__chatgpt-textarea {
    border: none;
    outline: none;
    width: 100%;
    color: var(--color-font);
    margin-right: 8px;
    font-family: inherit;
    max-height: 95px; // 5 rows
    height: fit-content;
    resize: none;
    white-space: pre-wrap;
    background-color: var(--color-app-bg);
  }

  .cv__chatgpt-send {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    border-radius: 6px;
    flex-shrink: 0;
    cursor: not-allowed;

    svg {
      transition: 0.3s;
      font-size: 20px;
    }

    &.active {
      cursor: pointer;

      &:hover {
        background-color: var(--color-theme);

        svg {
          color: var(--color-theme-font);
        }
      }
    }
  }
}

#cv__chatgpt {
  padding: 16px 20px 20px;
  flex-grow: 1;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  & > div {
    z-index: 1;
  }

  .cv__chatgpt-header {
    display: flex;
    justify-content: space-between;

    .cv__chatgpt-icons {
      display: flex;

      .active svg {
        color: var(--color-theme-text);
      }

      div:first-child {
        margin-right: 20px;

        svg {
          font-size: 28px;
        }
      }

      div:last-child svg {
        font-size: 25px;
      }

      svg {
        height: fit-content;
        vertical-align: top;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          scale: 1.2;
          color: var(--color-theme);
        }
      }
    }
  }

  .cv__chatgpt-funcs {
    display: flex;
    margin-bottom: 20px;

    .cv__chatgpt-func {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      text-align: center;
      padding: 10px;

      svg {
        font-size: 32px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 16px;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      &:hover {
        color: var(--color-theme);
      }
    }
  }

  .cv__chatgpt-msgs {
    cursor: zoom-in;
  }

  .cv__chatgpt-msg,
  .cv__chatgpt-textarea {
    font-size: 14px;
  }

  // background image
  svg.cv__chatgpt-bg {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
  }
}

// Dialog - API Key
.cv__dialogpro-apikey {
  padding: 20px;
  width: 660px;

  .cv__chatgpt-input-frame {
    display: flex;
    margin-bottom: 20px;

    .cv__chatgpt-input {
      width: 100%;
      margin-right: 20px;
      border: var(--card-border);
      border-radius: 10px;
      outline: none;
      padding: 8px 10px;
      background-color: var(--color-app-bg);
      color: var(--color-font);
    }

    button {
      flex-shrink: 0;

      & + button {
        margin-left: 10px;
      }
    }
  }

  .cv__link-question {
    display: inline-flex;
    align-items: center;

    svg {
      margin-right: 6px;
    }
  }
}

// Dialog - ZoomIn
.cv__dialogpro-large {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 860px;
  height: 740px;
}

// Dialog - Info of Funcs
.cv__dialogpro-info {
  padding: 20px;
  width: 650px;

  b {
    color: var(--color-theme-grey);
  }
}

// Dialog - Generate Your CV
// Dialog - Inspire CV Ideas
.cv__dialogpro-generation-pre,
.cv__dialogpro-inspiration-pre {
  padding: 20px;
  width: 400px;

  .MuiTextField-root,
  button {
    width: 100%;
    margin-top: 20px;
  }

  .MuiTextField-root + .MuiTextField-root {
    margin-top: 20px;
  }
}
