#cv__sidebar {
  width: 250px;
  border-right: var(--card-border);
  padding: 20px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--color-sidebar-bg);

  .cv__list {
    overflow-y: auto;
    margin-bottom: 20px;
    padding: 0;

    .cv__list-item-btn {
      border-radius: 30px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    svg {
      font-size: 22px;
    }

    span {
      font-weight: 600;
    }
  }

  .cv__sidebar-editseciton {
    padding: 20px;
    width: 320px;

    .MuiFormControl-root {
      width: 80%;
    }

    .cv__sidebar-editseciton-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cv__drag {
        font-size: 20px;
        color: var(--color-grey);
        cursor: grab;
      }
    }
  }

  footer {
    align-self: center;
    a {
      font-weight: 600;
    }
  }
}
