#cv__tips {
  flex-shrink: 0;
  padding: 16px 20px 20px;
  margin-bottom: 16px;

  .cv__tips-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .cv__tips-content {
    overflow: auto;
    padding-right: 6px;
    max-height: 200px;
  }

  @media screen and (min-width: 1471px) {
    .cv__tips-content {
      max-height: 280px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    margin-bottom: 10px;
  }

  p,
  ul,
  ol {
    font-size: 14px;
  }
}
