.cv__dialogpro-tempdetails {
  height: 70%;
  padding: 20px;
  display: flex;

  .cv__dialogpro-left {
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
    box-shadow: var(--box-shadow);

    img {
      height: 100%;
    }
  }

  .cv__dialogpro-right {
    width: 300px;
    flex-shrink: 0;
    margin-left: 20px;
    display: flex;
    flex-direction: column;

    h2,
    .cv__dialogpro-desc,
    .cv__dialogpro-author {
      margin-bottom: 20px;
    }

    .cv__dialogpro-author {
      display: flex;

      svg {
        margin-right: 8px;
      }
    }

    .cv__dialogpro-desc {
      opacity: 0.6;
    }

    button {
      margin-top: auto;
    }
  }
}
