#cv__editoraccordion {
  .cv__editoraccordion-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 12px;

    .cv__chip {
      background-color: var(--color-secondary);
      color: var(--color-theme-font);
      height: fit-content;
      padding: 8px 12px;
      border-radius: 20px;
    }
  }
}
