#cv__navbar {
  height: var(--height-navbar);
  width: 100%;
  background-color: var(--color-navbar-bg);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid var(--color-border-grey);
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 20;
  padding: 0 30px;
  transition: 0.3s;
  color: var(--color-font);

  & > div {
    flex: 1;
  }

  .cv__navbar-logo {
    font-weight: 600;
    font-size: 20px;
    transition: 0.3s;

    &:hover {
      color: var(--color-theme);
    }
  }

  .cv__navbar-template {
    display: flex;
    justify-content: center;
  }

  .cv__navbar-func {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;

    svg {
      vertical-align: top;
    }

    label {
      margin: 0;
    }

    .cv__navbar-user {
      display: flex;
      align-items: center;
      gap: 10px;
      height: var(--height-navbar);

      img {
        height: 32px;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
      }
    }
  }
}
