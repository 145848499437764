.cv__template-export {
  width: var(--width-template);
  height: auto;
  min-height: var(--height-template);
  overflow-y: auto;
  background-color: white;
  color: black;
  margin: 0 auto;
}

@media print {
  /* Hide elements you don't want to print */
  #cv__navbar {
    display: none;
  }
  /* Adjust the style when printing */
  .cv__layout {
    padding-top: 0;
  }
  .cv__card {
    border: none;
    border-radius: 0;
  }
  .cv__template-export {
    zoom: calc(794 / 800);
  }
}
