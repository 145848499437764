@color-text: #364153;
@color-grey: #c1c6cd;
@color-grey-light: #e9ecf1;
@color-grey-dark: #8891a1;
@gap-section: 14px;

#cvt__classic {
  padding: 40px;
  color: @color-text; // Text Default Color

  a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
  }

  b,
  .cvt__subtitle p {
    color: black;
  }

  li::before {
    color: @color-text;
  }

  .cvt__classic-section + .cvt__classic-section {
    margin-top: @gap-section;
  }

  p.cvt__classic-date {
    color: @color-grey-dark;
  }

  .cvt__classic-pinfo {
    position: relative;
    display: flex;
    gap: @gap-section;
    img {
      position: absolute;
      right: 0;
      height: 100px;
      border-radius: 10px;
      // border: 1.5px solid @color-grey;
    }
    div div {
      justify-content: flex-start;
      max-width: 620px;
    }
  }

  .cvt__classic-title {
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 30px;
    margin-bottom: 14px;
    .cvt__classic-jobtitle {
      color: @color-grey-dark;
      font-size: 18px;
      font-weight: normal;
    }
  }

  .cvt__classic-name {
    font-size: 30px;
    line-height: 1;
  }

  .cvt__classic-contact {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: @gap-section;

    div {
      display: flex;
      align-items: center;
      background-color: @color-grey-light;
      padding: 3px 5px;
      border-radius: 6px;
      gap: 6px;
    }
  }

  .cvt__classic-intro {
    margin-bottom: @gap-section;
    text-align: justify;
    line-height: 1.3;
  }

  .cvt__classic-section-title {
    margin-bottom: 10px;
    border-bottom: 1.5px solid @color-grey;
    text-transform: uppercase;
    line-height: 1.3;
  }

  .cvt__classic-edu-wrap,
  .cvt__classic-projects-wrap,
  .cvt__classic-experiences-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .cvt__classic-experiences-item,
  .cvt__classic-edu-item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .cvt__classic-skills-item,
  .cvt__classic-hobbies-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cvt__classic-skills {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .cvt__classic-skills-item {
      gap: 16px;

      .cvt__classic-skills-cate {
        flex-shrink: 0;
      }

      .cvt__classic-skills-skillset {
        display: flex;
        flex-wrap: wrap;
        gap: 6px 12px;

        // span {
        //   background-color: @color-grey-light;
        //   padding: 3px 5px;
        //   border-radius: 6px;
        // }
      }
    }
  }

  .cvt__classic-projects-item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .cvt__classic-certificates {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    div {
      background-color: @color-grey-light;
      padding: 3px 5px;
      border-radius: 6px;
    }
  }

  .cvt__classic-hobbies-items {
    .cvt__classic-skills
      .cvt__classic-skills-item.cvt__classic-skills-skillset();
  }
}
