#cv__preview {
  overflow: hidden;
  transition: 0.3s;
  margin: auto 0 20px;
  position: relative;
  cursor: zoom-in;
  box-shadow: var(--box-shadow);
  width: calc(0.26 * var(--width-template));
  height: calc(0.26 * var(--height-template));

  .cv__template-thumbnail {
    scale: 0.26; // (250px - 20px * 2 - 1px * 2) / var(--width-template)
    transform-origin: 0 0;
    width: var(--width-template);
    height: var(--height-template);
    background-color: white;
    transition: zoom 0.5s ease-in-out;
    color: black;
    pointer-events: none;

    ul li::before {
      color: black;
    }

    p,
    b,
    strong {
      letter-spacing: 0.2px;
    }
  }

  .cv__preview-sign {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    svg {
      font-size: 22px;
    }
  }
}

.cv__dialogpro-template {
  border: none;
  background-color: var(--color-bg-card-trsp);
  backdrop-filter: blur(6px);
  display: flex;

  .cv__template-large {
    #cv__preview.cv__template-thumbnail();
    scale: 1;
    // width: var(--width-template);
    width: calc(var(--width-template) + 15px);
    height: auto;
    overflow: auto;
    pointer-events: all;

    & > div {
      min-height: var(--height-template);
    }

    ul li::before {
      color: black;
    }
  }

  .cv__dialogpro-customstyle {
    width: 350px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .cv__dialogpro-customstyle-item {
      display: flex;
      align-items: center;
      margin: 30px 0;

      .cv__dialogpro-customstyle-title {
        width: 130px;
        flex-shrink: 0;
        margin-right: 15px;
      }
    }

    input[type="color"] {
      cursor: pointer;
      width: 100%;
      background: none;
      border: 1px solid var(--color-font);
      border-radius: 6px;
      overflow: hidden;
    }

    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
      margin: 0;
    }

    input[type="color"]::-webkit-color-swatch {
      border: none;
    }

    .cv__dialogpro-customstyle-suggestion {
      margin: auto 0 0;
    }
  }
}

.dark {
  .cv__template {
    position: relative;
  }

  .cv__template-thumbnail {
    filter: brightness(0.9);
  }
}
