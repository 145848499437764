#cv__404 {
  height: calc(100vh - var(--height-navbar));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 35%;
    min-width: 250px;
    max-width: 600px;
    margin-bottom: 60px;
  }
}