:root {
  --color-theme: #3d993d;
  --color-theme-dark: #367536;
  --color-theme-light: #8cd48c;
  --color-theme-bright: #59df59;
  --color-theme-transparent: #3d993d4d;
  --color-theme-grey: #87a787;
  --color-theme-font: #ffffffe8;
  --color-theme-text: var(--color-theme-grey);
  --color-secondary: #764a0d;
  --color-font: #313e31;
  --color-grey: #888;
  --color-black: #000000;
  --color-black-transparent: #000000cc;
  --color-white: #fff;
  --color-white-transparent: #ffffffcc;
  --color-app-bg: #f5faf5;
  --color-navbar-bg: var(--color-white-transparent);
  --color-sidebar-bg: white;
  --color-home-bg: var(--color-sidebar-bg);
  --color-footer-bg: #eee;
  --color-bg-grey: #0000001f;
  --color-bg-card: white;
  --color-bg-card-trsp: #ffffffd2;
  --color-bg-msg-odd: var(--color-theme-light);
  --color-bg-msg-even: var(--color-footer-bg);
  --color-border-grey: var(--color-bg-grey);
  --color-unselected-font: #0000004d;
  --color-backdrop: #0000004d;
  --box-shadow: 0 2px 16px -3px rgba(0, 0, 0, 0.15);
  --box-shadow-button: 0 1px 8px -2px rgba(0, 0, 0, 0.4);
  --box-shadow-bar: 2px 1px 8px -3px rgba(0, 0, 0, 0.3);
  --width-template: 800px;
  --height-template: calc(var(--width-template) * sqrt(2));
  --height-navbar: 50px;
  --height-footer: 50px;
  --gap: 20px;
  --card-border: 1px solid var(--color-border-grey);
  --card-border-hover: 1px solid var(--color-theme);
  --animate-delay: 0.5s;
}

:root .dark {
  // --color-theme: #2A6B2A;
  // --color-secondary: #55360c;
  --color-theme-text: var(--color-theme-light);
  --color-theme-grey: #9ec49e;
  --color-font: var(--color-white);
  --color-app-bg: black;
  --color-navbar-bg: #31343ecc;
  --color-footer-bg: #31343e;
  --color-input-bg: var(--color-black);
  --color-sidebar-bg: var(--color-bg-card);
  --color-home-bg: var(--color-sidebar-bg);
  --color-bg-grey: #3c404c;
  --color-bg-card: #16181c;
  --color-bg-card-trsp: #16181ce6;
  --color-bg-msg-odd: var(--color-theme-dark);
  --color-bg-msg-even: var(--color-bg-grey);
  --color-backdrop: #ffffff4d;
  --color-border-grey: var(--color-bg-grey);
  --box-shadow: 0 2px 16px -3px rgba(255, 255, 255, 0.35);
  --box-shadow-button: 0 2px 6px -3px rgba(255, 255, 255, 0.3);
  --card-border: 1px solid var(--color-border-grey);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  letter-spacing: 0.2px;
  font-family: system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif;
}

body {
  @media (max-width: 900px) {
    font-size: 14px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cv__card {
  border: var(--card-border);
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--color-bg-card);

  &:not(.cv__template-export) ul li::before {
    color: var(--color-theme-text);
  }
  &:not(.cv__upload-cv-data, .cv__dialogpro-save) ol li::before {
    color: var(--color-theme-grey);
  }

  ul,
  ol {
    list-style-type: none;
    line-height: 1.3;
    text-align: justify;
  }

  ul {
    li {
      padding-left: 1.5em;
      position: relative;
      word-wrap: break-word;

      &::before {
        position: absolute;
        content: "●";
        left: 0;
        top: 0.2em;
        font-size: 0.8em;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  ol {
    counter-reset: li;

    li {
      padding-left: 1.5em;
      position: relative;
      word-wrap: break-word;

      &::before {
        position: absolute;
        content: counter(li) ".";
        counter-increment: li 1;
        left: 0;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}

.cv__card-link {
  .cv__card();
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    border: var(--card-border-hover);
  }
}

.cv__scrollbar {
  /* Change the background of the scrollbar track */
  &::-webkit-scrollbar-track {
    background-color: #88888838;
    border-radius: 4px;
  }

  /* Set the style of the scrollbar */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  /* Set the style of the scrollbar slider */
  &::-webkit-scrollbar-thumb {
    background-color: #999; /* Background colour of the slider */
    border-radius: 4px; /* Slider rounding */
  }

  /* Styles when the mouse hovers over the scrollbar */
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-theme-text); /* Background colour on hover */
  }
}

a {
  text-decoration: none;
  color: var(--color-font);
  transition: 0.3s;
  cursor: pointer;
  display: inline;

  &.cv__link-text:hover {
    color: var(--color-theme);
  }

  &.cv__link-question {
    font-size: 14px;
    color: var(--color-grey);

    &:hover {
      color: var(--color-theme);
    }
  }
}

input,
textarea {
  font-size: 16px;
}

pre {
  overflow: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: 600;
}

@keyframes zoomIn {
  from {
    scale: 0.9;
    opacity: 0;
  }
  to {
    scale: 1;
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    scale: 1;
    opacity: 1;
  }
  to {
    scale: 0.9;
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
