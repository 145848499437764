#cv__mdeditorpro {
  background-color: transparent;
  border-radius: 12px;
  overflow: hidden;
  transition: 0.3s;

  .cv__mdeditorpro-label {
    font-size: 16px;
    margin-right: 20px;
    font-weight: 600;
  }

  div {
    transition: 0.3s;
  }

  .w-md-editor-toolbar {
    padding: 8px 14px;
  }

  .wmde-markdown {
    background-color: transparent;
    font-family: initial;

    a {
      text-decoration: none;
      color: var(--color-font);
      transition: 0.3s;
      cursor: pointer;
      display: inline;
      text-decoration: underline;
    }

    p {
      font-size: 14px;
    }
  }

  .textarea {
    overflow-y: auto;
  }
}
