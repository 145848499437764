footer#cv__footer {
  flex-shrink: 0;
  height: var(--height-footer);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--color-footer-bg);

  .cv__link-text {
    font-weight: 600;
  }

  .right {
    display: flex;
    gap: 12px;
  }
}
