#cv__popupmenu {
  position: relative;
  width: fit-content;
  cursor: default;

  .cv__popupmenu-flex {
    display: flex;
    align-items: center;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 600px;
    line-height: var(--height-navbar);
  }

  ul.cv__popupmenu-list {
    list-style: none;
    z-index: 22;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: 0 0;
    animation: zoomOut 0.3s forwards;
    box-shadow: var(--box-shadow);

    &.zoomIn {
      animation: zoomIn 0.3s forwards;
    }

    li {
      padding: 10px 14px;
      transition: 0.3s;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        background-color: var(--color-theme-transparent);
      }

      &:not(:last-child) {
        border-bottom: var(--card-border);
      }
    }
  }
}
