#cv__home .cv__home-main {
  position: relative;
  background-color: var(--color-home-bg);
  margin-top: 100vh;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .cv__home-section {
    padding: 40px 20px;
    .cv__home-title {
      text-align: center;
    }
  }

  .cv__home-template {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.cv__home-hero {
  position: fixed;
  top: var(--height-navbar);
  left: 0;
  width: 100%;
  height: calc(100vh - var(--height-navbar));
  display: flex;
  overflow: hidden;

  .cv__home-hero-content {
    width: fit-content;
    padding-left: 40px;
    flex-shrink: 0;
    align-self: center;
    z-index: 5;

    h1 {
      font-size: 4vw;
      margin-bottom: 10px;
      color: var(--color-font);
    }
  }

  .cv__home-hero-img-groups {
    display: flex;
    position: fixed;
    inset: var(--height-navbar) calc((var(--height-navbar) - 100vh) * sqrt(0.5))
      auto auto;
    rotate: 45deg;
    width: calc((100vh - var(--height-navbar)) * sqrt(0.5));
    gap: var(--gap);
    transform-origin: 0 0;
    animation: slideUp 45s linear infinite;
    cursor: pointer;

    .cv__home-hero-img-group {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: var(--gap);

      &:last-child {
        transform: translateY(calc(25vh - 9.571px));
      }

      .cv__home-hero-img-box {
        display: block;
        flex-shrink: 0;
        width: 100%;
        aspect-ratio: 1 / sqrt(2);
        border-radius: 10px;
        overflow: hidden;
        border: var(--card-border);
        transition: 0.3s;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &:hover .cv__home-hero-img-box {
      border-color: var(--color-theme);
    }

    .dark & img {
      opacity: 0.4;
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50%);
  }
}
