#cv__start {
  height: calc(100vh - var(--height-navbar) - var(--height-footer));
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 2.75rem;
    text-align: center;
  }
}

.cv__start-box-wrap {
  margin-top: 40px;
  display: flex;
  justify-content: center;

  .cv__start-box {
    width: 480px;
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: 40px;
    }

    .cv__start-box-img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 280px;
      border-bottom: var(--card-border);

      img {
        max-width: 80%;
        max-height: 80%;
      }
    }

    .cv__start-text-wrap {
      padding: 20px;
      .cv__start-title {
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
      }

      .cv__start-desc {
        margin-top: 10px;
        text-align: center;
        color: var(--color-grey);
      }
    }
  }
}

.cv__upload-cv-data {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 600px;

  h2 {
    margin-bottom: 20px;
  }

  .cv__upload-btns {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    label {
      flex: 1;
    }
  }
}
