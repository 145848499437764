.cv__layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: var(--height-navbar);
  background-color: var(--color-app-bg);
  color: var(--color-font);
  transition: 0.3s;
  overflow-x: hidden;
}
