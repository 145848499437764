#cv__privacy {
  margin: 20px auto;
  max-width: 1000px;
  text-align: justify;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 20px 0 10px;
    color: var(--color-theme-grey);
  }

  p,
  ul,
  ol {
    margin-bottom: 10px;
  }

  ul li::before {
    color: var(--color-theme-grey);
  }

  ul,
  ol {
    list-style-type: none;
    line-height: 1.3;
    text-align: justify;
  }

  ul {
    li {
      padding-left: 1.5em;
      position: relative;
      word-wrap: break-word;

      &::before {
        position: absolute;
        content: "●";
        left: 0;
        top: 0.15em;
        font-size: 0.8em;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  ol {
    counter-reset: li;

    li {
      padding-left: 1.5em;
      position: relative;
      word-wrap: break-word;

      &::before {
        position: absolute;
        content: counter(li) ".";
        counter-increment: li 1;
        left: 0;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  a {
    color: var(--color-font);
    transition: 0.3s;
    cursor: pointer;
    display: inline;
    font-weight: 600;
    text-decoration: underline;

    &:hover {
      color: var(--color-theme);
    }
  }
}
